<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-text-field
                v-model="shopModelLocale.apiKey"
                :type="isApiKeyVisible ? 'text' : 'password'"
                label="Api Key"
                outlined
                dense
                readonly
              >
                <template slot="append">
                  <v-icon
                    size="20"
                    class="mr-3"
                    @click="isApiKeyVisible = !isApiKeyVisible"
                  >
                    {{ isApiKeyVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline }}
                  </v-icon>
                  <v-icon
                    size="20"
                    @click="copyToClipboard(shopModelLocale.apiKey)"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                </template>
              </v-text-field>

              <v-text-field
                v-model="shopModelLocale.secretKey"
                :type="isSecretKeyVisible ? 'text' : 'password'"
                label="Secret Key"
                outlined
                dense
                persistent-hint
                readonly
              >
                <template slot="append">
                  <v-icon
                    size="20"
                    class="mr-3"
                    @click="isSecretKeyVisible = !isSecretKeyVisible"
                  >
                    {{ isSecretKeyVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline }}
                  </v-icon>
                  <v-icon
                    size="20"
                    @click="copyToClipboard(shopModelLocale.secretKey)"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="100"
                src="@/assets/images/3d-characters/pose-2.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <v-card-text class="api-creds text-center mx-auto">
          <v-btn
            color="error"
            outlined
            class="mt-3"
            :loading="loading"
            @click="generateApiCredentials"
          >
            Generate New Api Credentials
          </v-btn>
          <p class="text-base text--primary font-weight-semibold mt-3">
            Take into account that generating new API Credentials will impact in your current integration
          </p>
          <p class="text-sm text--primary">
            Click the button above to create new Api Credentials. (The generated API Credentials will not be saved or replaced unless you click on "Save Shanges").
          </p>
        </v-card-text>

        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            :loading="loading"
            @click="saveChanges"
          >
            Save changes
          </v-btn>

          <v-btn
            class="me-3 mt-3"
            color="secondary"
            outlined
            :loading="loading"
            @click="sendApiCredentialsByEmail"
          >
            Send Api Credentials
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline, mdiContentCopy } from '@mdi/js'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    shopModel: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    const shopModelLocale = ref(JSON.parse(JSON.stringify(props.shopModel)))
    const loading = ref(false)
    const isApiKeyVisible = ref(false)
    const isSecretKeyVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('12345678')
    const newPassword = ref('87654321')
    const cPassword = ref('87654321')

    const emitErrorMessage = errors => {
      let msg = ''
      errors.forEach(element => {
        msg += `<li>${element.message}</li>`
      })
      emit('on-show-message', { message: msg, type: 'error' })
    }

    const copyToClipboard = value => {
      navigator.clipboard.writeText(value)
      emit('on-show-message', { message: 'Copied to clipboard!', type: 'info' })
    }

    const sendApiCredentialsByEmail = () => {
      loading.value = true
      store.dispatch('onboarding-shops/sendApiCredentialsByEmail', shopModelLocale.value.shopId).then(() => {
        emit('on-show-message', { message: 'Your API credentials have been sent', type: 'info' })
      }).catch(e => {
        emitErrorMessage(e.request.response)
      }).finally(() => {
        loading.value = false
      })
    }

    const generateApiCredentials = () => {
      loading.value = true
      store.dispatch('onboarding-shops/generateApiCredentials').then(response => {
        shopModelLocale.value.apiKey = response.data.apiKey
        shopModelLocale.value.secretKey = response.data.secretKey
        emit('on-show-message', { message: 'Your API credentials have been generated. Please save changes to confirm them.', type: 'info' })
      }).catch(e => {
        emitErrorMessage(e.request.response)
      }).finally(() => {
        loading.value = false
      })
    }

    const saveChanges = () => {
      loading.value = true
      store.dispatch('onboarding-shops/update', shopModelLocale.value).then(() => {
        emit('on-show-message', { message: 'Your API credentials have been saved.', type: 'info' })
      }).catch(e => {
        emitErrorMessage(e.request.response)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      isApiKeyVisible,
      isSecretKeyVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      shopModelLocale,
      loading,
      copyToClipboard,
      emitErrorMessage,
      saveChanges,
      generateApiCredentials,
      sendApiCredentialsByEmail,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiContentCopy,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.api-creds {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
